import { mdiCart, mdiEarth, mdiInstagram, mdiMenu } from "@mdi/js";
import { navigate, reload } from "vike/client/router";
import {
  AnimationConfig,
  Controller,
  animated,
  config as presets,
  useSpring,
  useSpringRef,
  useTransition,
} from "@react-spring/web";
import { Handler, useGesture } from "@use-gesture/react";
import { Fragment } from "preact";
import {
  ChangeEvent,
  PropsWithChildren,
  useEffect,
  useLayoutEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "preact/compat";
import { PageContext } from "vike/types";
import MainMenuItem from "../components/menuItem/index.jsx";
import { Sections } from "../components/sections/index.jsx";
import ProductsSlide, { Item } from "../components/slide/index.jsx";
import { useCSSAnimations } from "../lib/animation.js";
import { GraphicsIconDown } from "../lib/graphics.jsx";
import {
  getLocalePathname,
  useLocaleCountry,
  useLocaleLanguage,
  useLocalePathname,
} from "../lib/pathnames.jsx";
import "../lib/scrollend";
import {
  backdropToCircle,
  getPathsForSettings,
  logoPath,
} from "../lib/shapes.js";
import { getLocaleFromContext, locales } from "../lib/locales.js";
import { SummaryCollection } from "./(collections)/@id/Summary.jsx";
import type { Store } from "./+store.server.js";
import { SummaryHome } from "./home/Summary.js";
import "./Layout.scss";
import "./qna/@section/style.scss";
import { SummaryQNA } from "./qna/@section/Summary.js";
import { useCart } from "../components/cart/provider.js";
import { Link } from "../components/link/index.jsx";

const initialDate = new Date();

export default function LayoutClient({
  children,
  ...context
}: PropsWithChildren<PageContext>) {
  const { menus, collections, products } = context.store as Store;
  const menuItems = menus.find((m) => m.handle == "main-menu")?.items;
  const Pages = Object.fromEntries(
    Object.values(menuItems).map((menuItem) => {
      var handle = menuItem.url.split(/\//g).pop() || "home";
      if (locales.includes(handle)) handle = "home";
      return [
        handle,

        handle == "home"
          ? SummaryHome(context)
          : handle == "qna"
          ? SummaryQNA(context)
          : SummaryCollection(context, handle),
        ,
      ];
    })
  );
  const pathname = useLocalePathname();
  const country = useLocaleCountry();
  const language = useLocaleLanguage();
  var focusedSection = pathname.split(/\//g)[1];
  if (focusedSection == "collections") {
    focusedSection = pathname.split(/\//g)[2];
  }
  const last = useRef({
    index: 0,
    focusedIndex: -1,
    maxIndex: -1,
    logoCollapsed: false,
    notRenderedYet: true,
    dragDate: initialDate,
    dragStartY: 0,
    dragDirection: 0,
    dragController: null as any as Controller | undefined,
    focusing: -1,
    routeUpdate: 0 as unknown as ReturnType<typeof setTimeout>,
    route: focusedSection,
    animation: null as ReturnType<typeof useCSSAnimations> | null,
  });
  last.current.animation ||= useCSSAnimations();
  const collection = collections.find((c) => c.handle == focusedSection);

  const [{ width, height }, setDimensions] = useState(getDimensions);
  const [hydrated, setHydrated] = useState(false);
  const isDesktop = width >= 800 && height >= 700;
  const [, forceRender] = useReducer((s) => s++, 0);

  const { cart, totalQuantity } = useCart();

  const pages: Item[] = useMemo(
    () =>
      menuItems.map((menuItem, index) => {
        var handle = menuItem.url.split(/\//g).pop() || "home";
        if (locales.includes(handle)) handle = "home";

        return {
          ...menuItem,
          index,
          key: handle,
          variables: {},
          children: Pages[handle] || [],
        };
      }),
    []
  );
  const activeSection =
    typeof location == "undefined"
      ? null
      : new URLSearchParams(location.search).get("section");

  const activeIndex =
    pages.find((p) => p.key == (focusedSection || activeSection))?.index ?? 0;
  const focusedIndex = pages.find((p) => p.key == focusedSection)?.index ?? -1;
  function getDimensions() {
    return {
      width: Math.min(
        1440,
        typeof window == "undefined" ? 1200 : window?.innerWidth ?? 1280
      ),
      height: Math.min(
        1200,
        typeof window == "undefined" ? 1080 : window?.innerHeight ?? 1024
      ),
    };
  }

  function route(type: "pushState" | "replaceState", rawUrl: string) {
    // @ts-ignore
    navigate(getLocalePathname(rawUrl), {
      keepScrollPosition: true,
      overwriteLastHistoryEntry: type == "replaceState",
    });
  }
  const padding = isDesktop ? 10 : 0;
  const borders = padding + (isDesktop ? 10 : 5) + 2;
  const logoRadius = isDesktop ? 70 : 30;
  function focusIndex(focused: number, active = focused) {
    if (focused == 0) focused = -1;
    if (focused == last.current.focusedIndex && active == last.current.index) {
      return;
    }
    last.current.index = active;
    last.current.focusedIndex = focusedIndex;
    last.current.maxIndex =
      active == 0
        ? active
        : Math.max(last.current.maxIndex, active, focused > -1 ? 10 : 0);
    if (focused == -1) {
      if (focusedSection) {
        // clear out content
        route("pushState", `/?section=${focusedSection}`);
      } else if (active == 0 || active == -1) {
        route("replaceState", `/`);
      } else {
        route("replaceState", `/?section=${pages[active].key}`);
      }
    } else {
      route("pushState", `/${pages[focused].key}/`);
    }
    return true;
  }

  useEffect(() => {
    setTimeout(() => {
      for (const script of Array.from(document.querySelectorAll("script"))) {
        script.remove();
      }
    }, 100);
    document.addEventListener("render", forceRender);
    return () => {
      document.removeEventListener("render", forceRender);
    };
  }, []);

  useMemo(() => {
    recompute(focusedIndex, activeIndex);
  }, [hydrated, width, height, focusedIndex, activeIndex]);

  useEffect(() => {
    if (typeof window != "undefined") last.current.animation?.start();
  }, [hydrated, width, height, focusedIndex, activeIndex]);

  const paths = getPathsForSettings({
    width,
    height,
    padding,
    borders,
    isDesktop,
  });
  const logoPaths = logoPath(100);

  last.current.index = activeIndex;
  last.current.focusedIndex = focusedIndex;

  function recompute(focused = focusedIndex, active = activeIndex) {
    if (typeof window != "undefined")
      pages.map((page) => {
        const el = document.querySelector(
          '[data-ref="content-' + page.key + '"]'
        ) as HTMLElement;
        if (el) {
          const css = window.getComputedStyle(el);
          [
            "--slide-height",
            "--slide-translate-y",
            "--slide-adjusted-y",
            "--slide-stack-distance",
            "--slide-focused-translate-y",
            "--slide-active-translate-y",
          ].forEach((property) => {
            page.variables[property] = calc(
              css
                .getPropertyValue(property)
                .replace(/-?\d\s*\/\s*100\s*\*\s*100/m, String(active)),
              width,
              height
            );
          });

          page.variables["--slide-target-translate-y"] =
            page.index == focused
              ? page.variables["--slide-focused-translate-y"]
              : page.variables["--slide-active-translate-y"];
          page.variables["--slide-translate-y"] =
            page.index == focused
              ? page.variables["--slide-focused-translate-y"]
              : page.index <= activeIndex
              ? page.variables["--slide-active-translate-y"]
              : 0;
        }
      });
  }
  function slideConfig(key: string, item: Item) {
    const isGesturing =
      Number(new Date()) - Number(last.current.dragDate) < 100;
    if (key == "--collapsed" && item.key == "home" && !isGesturing) {
      return {
        tension: 60,
        friction: 20,
        precision: 0.0005,
        round: 0.0002,
      };
    }
    if (key == "--slide-current-y") {
      return {
        tension: isGesturing
          ? focusedIndex == item.index
            ? 180
            : 140
          : activeIndex == 0
          ? 30
          : 90,
        friction: isGesturing ? (focusedIndex == item.index ? 50 : 40) : 25,
        precision: 0.3,
        round: 0.1,
      };
    }
    if (key == "--delayed-focused") {
      return {
        tension: 48,
        friction: 19,
        precision: 0.0,
        round: 0.001,
        mass: 2.1,
      };
    }
    return {
      tension: isGesturing ? 220 : 100,
      friction: isGesturing ? 40 : 20,
      precision: 0.0005,
      round: 0.0002,
    };
  }
  function config(key: string) {
    var base: Partial<AnimationConfig> = {};
    if (key == "--logo-scale-progress") {
      base.tension = 140;
      base.friction = 45;
    } else if (key == "--logo-morph-progress") {
      base.tension = 136;
      base.friction = 33;
      base.mass = 3;
    } else if (key == "--logo-coloration") {
      base.tension = 210;
      base.friction = 120;
      base.restVelocity = 0.001;
      base.precision = 0.003;
    } else if (key == "--logo-translation-progress") {
      base.tension = 83;
      base.friction = 20;
    } else if (key == "--logo-rotation") {
      base.tension = 30;
      base.friction = 10;
    } else if (key == "--path-inner-progress") {
      base.tension = 80;
      base.friction = 50;
    } else if (key == "--path-outer-progress") {
      base.tension = 9;
      base.friction = 8;
      base.mass = 2.5;
    } else if (key == "--focus-delayed-progress") {
      base.tension = 48;
      base.friction = 19;
      base.precision = 0.0;
      base.round = 0.001;
      base.mass = 2.1;
    } else if (key == "--path-outer-outline-progress") {
      base.tension = 25;
      base.friction = 15;
      base.restVelocity = 2;
      base.mass = 1.5;
    } else if (
      key == "--logo-rotation" ||
      key == "--path-inner-width" ||
      key == "--path-outer-outline-width"
    ) {
      base.tension = 280;
      base.friction = 120;
      base.restVelocity = 0.001;
      base.precision = 0.003;
    } else {
      base.tension = 280;
      base.friction = 60;
    }

    if (
      key == "--path-inner-progress" ||
      key == "--path-outer-progress" ||
      key == "--path-outer-outline-progress"
    ) {
      base.restVelocity = 0.0001;
      base.precision = 0.0003;
    }

    return base;
  }

  function immediate(key: string) {
    return last.current.notRenderedYet;
  }

  const [springs, springApi] = useSpring(
    () => ({
      from: {
        "--path-inner-progress": 0,
        "--path-inner-width": 0,
        "--path-outer-progress": 0,
        "--path-outer-outline-width": 0,
        "--path-outer-outline-progress": 0,
        "--logo-morph-progress": 0,
        "--logo-rotation": 0,
        "--logo-coloration": 0,
        "--focus-progress": focusedIndex == -1 ? 0 : 1,
        "--focus-delayed-progress": focusedIndex == -1 ? 0 : 1,
        "--logo-translation-progress": 1,
        "--logo-scale-progress": 1,
      },
      config,
      delay(key) {
        return key == "--focus-delayed-progress" ? 600 : 0;
      },
      onChange(_: any, controller) {
        for (const spring in controller.springs) {
          //console.log(spring, controller.springs[spring].get());

          last.current.animation?.set(
            document.body,
            spring,
            controller.springs[spring].get()
          );
        }
      },
      onStart: () => {},
    }),
    []
  );

  const onTranslateYChange = (controller: Controller) => {
    //if (controller.springs["opacity"].get() == 0) {
    //  controller.item.key;
    //}
    const elements = Array.from(
      // @ts-ignore
      (controller.elements ||= Array.from(
        document.querySelectorAll('[data-ref*="-' + controller.item.key + '"]')
      ))
    ) as HTMLElement[];
    if (controller.item.key == "home") {
      controller = ref.current.find((c) => c.item.key == "onyx")!;
    }
    for (const el of elements) {
      for (const spring in controller.springs) {
        //requestAnimationFrame(() => {
        last.current.animation?.set(
          el,
          spring,
          controller.springs[spring].get()
        );
        //});
      }
    }

    if (last.current.focusing != focusedIndex) {
      last.current.focusing = focusedIndex;
      springApi.start({
        "--focus-progress": focusedIndex == -1 ? 0 : 1,
        "--focus-delayed-progress": focusedIndex == -1 ? 0 : 1,
        config,
        immediate,

        delay(key) {
          return !last.current.notRenderedYet &&
            focusedIndex > -1 &&
            key == "--focus-delayed-progress"
            ? 600
            : 0;
        },
      });
    }

    const translateY = controller.springs["--slide-current-y"]?.get();
    const slideProgress = Math.max(
      0,
      Math.min(
        1,
        (translateY || 0.000001) /
          (controller.item.variables["--slide-target-translate-y"] || 0.000001)
      )
    );
    const progress = slideProgress;
    if (controller.item.key == "onyx") {
      const innerLineProgress =
        last.current.maxIndex > 1
          ? 1
          : controller.item.index == 1
          ? progress
          : null;
      //console.log(progress, innerLineProgress, controller.item.key);
      if (innerLineProgress != null) {
        //console.log(innerLineProgress);
        springApi.start({
          "--path-inner-progress": innerLineProgress,
          "--path-inner-width": innerLineProgress,
          "--logo-translation-progress": progress,
          "--logo-scale-progress": Math.min(1, progress * 1.2),
          "--logo-rotation": Math.min(1, progress * 1.3),
          "--logo-coloration": (progress - 0.5) * 2,
          //immediate,
          config,
        });
      }
      springApi.start({
        "--logo-morph-progress": progress,
        immediate,
        config,
      });
    }

    const outerLineProgress =
      focusedIndex > -1
        ? 1
        : activeIndex == 0
        ? 0
        : last.current.maxIndex >= 2
        ? 1
        : activeIndex == 0
        ? 0
        : controller.item.index == 2
        ? progress
        : null;
    if (outerLineProgress != null) {
      springApi.start({
        "--path-outer-progress": outerLineProgress,
        "--path-outer-outline-progress": outerLineProgress,
        "--path-outer-outline-width": outerLineProgress,
        immediate,
        config,
      });
    }
    const distance =
      controller.item.variables["--slide-stack-distance"] + borders * 2;
    const slideProgressTowardsMenu =
      controller.springs["--slide-progress-towards-menu"].get() > 0.7 &&
      activeIndex > controller.item.index
        ? 1
        : Math.max(0, Math.min(1, -translateY / distance));
    const slideProgressPastMenu =
      controller.springs["--slide-progress-past-menu"].get() > 0.7 &&
      activeIndex > controller.item.index
        ? 1
        : Math.max(
            0,
            Math.min(
              1,
              (translateY + distance) /
                (controller.item.variables["--slide-target-translate-y"] +
                  distance)
            )
          );

    if (controller.item.key == "onyx") {
      (document.body.firstElementChild as HTMLElement).style.setProperty(
        "--first-slide-progress-towards-menu",
        String(slideProgress)
      );
      (document.body.firstElementChild as HTMLElement).style.opacity =
        String(slideProgress);
    }
    const prev = pages[controller.item.index - 1];
    if (prev)
      document
        .querySelector('.slide[data-ref="content-' + prev.key + '"]')
        ?.classList.toggle("covered", slideProgressPastMenu > 0.98);

    controller.start({
      "--slide-progress-past-menu": slideProgressPastMenu,
      "--slide-progress-towards-menu": slideProgressTowardsMenu,
      "--slide-progress": slideProgress,
      config: (key) => slideConfig(key, controller.item),
      onChange: (_, controller) => onTranslateYChange(controller),
      immediate,
    });
  };

  const onDrag: Handler<"drag"> = (event) => {
    if (event.axis == "x") {
      return;
    }
    if (event.tap) return;

    if (event.movement[1] > 0) {
      if (activeIndex == 0) return;
    } else {
      if (activeIndex == pages.length) return;
    }
    const controller = ref.current.find(
      (c) =>
        c.item.index ==
        activeIndex +
          (last.current.dragDirection == 1 || event.movement[1] > 0 ? 0 : 1)
    );
    if (!controller) return;

    if (controller != last.current.dragController) {
      if (last.current.dragController) forceRender(0);

      last.current.dragController = controller;
      last.current.dragStartY = controller.springs["--slide-current-y"].get();
    }
    last.current.dragDate = new Date();
    var displacementY =
      controller.springs["--slide-current-y"].get() - last.current.dragStartY;
    if (displacementY > 100) {
      last.current.dragDirection = 1;
    }
    controller.start({
      "--slide-current-y": Math.min(
        -controller.item.variables["--slide-active-translate-y"],
        Math.max(
          controller.item.variables["--slide-active-translate-y"],
          last.current.dragStartY + event.movement[1]
        )
      ),
      config: controller.item.key == "footer" ? presets.slow : presets.stiff,
      onChange: (value, controller, item) => {
        onTranslateYChange(controller);
      },
    });
  };
  const onDragEnd: Handler<"drag"> = (event) => {
    if (event.tap) return;
    if (event.axis == "x") {
      return;
      focusIndex(focusedIndex == activeIndex ? -1 : activeIndex, activeIndex);
    } else {
      const { dragController: controller, dragDirection } = last.current;
      if (!controller) return;
      last.current.dragDate = initialDate;
      last.current.dragDirection = 0;
      last.current.dragController = undefined;
      const threshold = window.innerHeight / 10;
      const velocityDelta = event.velocity[1] * event.direction[1];
      const velocityDirection =
        velocityDelta < -1 ? -1 : velocityDelta > 1 ? 1 : 0;
      const distanceDirection =
        event.movement[1] < -threshold
          ? -1
          : event.movement[1] > threshold
          ? 1
          : 0;
      const direction = velocityDirection || distanceDirection;
      if (direction) {
        if (direction < 0) {
          if (activeIndex < pages.length - 1 && dragDirection != 1)
            return focusIndex(-1, activeIndex + 1);
        } else {
          if (activeIndex > 0) return focusIndex(-1, activeIndex - 1);
        }
      }
      forceRender(0);
    }
  };
  const bind = useGesture(
    {
      onDrag,
      onDragEnd,
      onWheelEnd(event) {
        console.log("end");
        onDragEnd({
          ...(event as any),
          movement: [
            -event.movement[0],
            Math.max(-window.innerHeight / 2, -event.movement[1]),
          ],
          direction: [-event.direction[0], -event.direction[1]],
        });
      },
      onWheel(event) {
        onDrag({
          ...(event as any),
          movement: [
            -event.movement[0],
            Math.max(-window.innerHeight / 2, -event.movement[1]),
          ],
          direction: [-event.direction[0], -event.direction[1]],
        });
      },
    },
    {
      drag: {
        axis: focusedIndex > -1 ? "x" : activeIndex == 0 ? "y" : "lock",
        filterTaps: true,
        axisThreshold: {
          touch: 10,
          mouse: 10,
        },
        threshold: 10,
      },
      wheel: {
        enabled: focusedIndex == -1,
        axis: focusedIndex > -1 ? "x" : activeIndex == 0 ? "y" : "lock",
        rubberband: false,
      },
    }
  );

  const ref = useSpringRef();
  ref.current.forEach((r, index) => {
    r.item = pages[index];
  });
  const [transition] = useTransition(
    pages,
    {
      expires: false,
      ref: ref,
      keys: (item) => item.key,
      immediate,
      enter: (item, index) => {
        return {
          "--collapsed": 0,
          "--delayed-focused":
            focusedIndex == index || (focusedIndex > -1 && index == 0) ? 1 : 0,
          "--focused":
            focusedIndex == index || (focusedIndex > -1 && index == 0) ? 1 : 0,
          "--active":
            focusedIndex == index || (focusedIndex > -1 && index == 0) ? 1 : 0,
          "--current": focusedIndex == index ? 1 : 0,
          "--slide-progress-towards-menu": 0,
          "--slide-progress-past-menu":
            focusedIndex == index || (focusedIndex > -1 && index <= 1) ? 1 : 0,
          "--slide-progress": 0,
          transformOrigin: "top center",
          "--slide-current-y": item.variables["--slide-translate-y"],
          onChange: (_, controller) => {
            onTranslateYChange(controller);
          },
          config: (key) => slideConfig(key, item),
        };
      },
      update: (item, index) => {
        return {
          "--collapsed": activeIndex > index ? 1 : 0,
          "--delayed-focused": focusedIndex == index ? 1 : 0,
          "--focused": focusedIndex == index ? 1 : 0,
          "--current": focusedIndex == index || activeIndex == index ? 1 : 0,
          "--active":
            focusedIndex == index || activeIndex + (index == 0 ? 0 : 1) > index
              ? 1
              : 0,
          "--slide-current-y": item.variables["--slide-translate-y"],
          opacity:
            focusedIndex == -1 ||
            focusedIndex == item.index ||
            (item.index == 0 && isDesktop)
              ? 1
              : 0,
          onChange: (_, controller) => {
            onTranslateYChange(controller);
          },
          config: (key) => slideConfig(key, item),
          immediate(key) {
            return key == "opacity" && focusedIndex == -1
              ? true
              : last.current.notRenderedYet;
          },
          delay: (key) =>
            key == "opacity" && focusedIndex > -1
              ? 600
              : key == "--delayed-focused" && focusedIndex == index
              ? 1000
              : focusedIndex == item.index
              ? 50
              : Math.max(0, 0) * 200,
        };
      },
      leave: (item, index) => {
        return { transformY: 0 };
      },
      onChange: (item, controller) => {
        onTranslateYChange(controller);
      },
    },
    [activeIndex, focusIndex, width, height]
  );
  useLayoutEffect(() => {
    function resize() {
      const size = getDimensions();
      setDimensions(size);
      document.documentElement.style.setProperty(
        "--screen-width",
        String(size.width) + "px"
      );
      document.documentElement.style.setProperty(
        "--screen-height",
        String(size.height) + "px"
      );
      document.documentElement.classList.toggle(
        "touch",
        "ontouchstart" in document.documentElement
      );
      document.documentElement.classList.toggle(
        "no-touch",
        !("ontouchstart" in document.documentElement)
      );
    }
    window.addEventListener(
      "scroll",
      (e) => {
        const el = e.target as HTMLElement;
        if (!el) return;
        el.querySelector(".scroll-shadows")?.classList.toggle(
          "scrolled-top",
          el.scrollTop > 40
        );
        // el.classList.toggle(
        //   "scrolled-bottom",
        //   el.scrollTop < el.scrollHeight - el.offsetHeight - 40
        // );
      },
      { passive: true, capture: true }
    );
    window.addEventListener("resize", resize);
    window.addEventListener("orientationchange", resize);
    document.addEventListener("visibilitychange", resize);
    setTimeout(() => {
      document.documentElement.classList.add("already-rendered");
    }, 1000);
    resize();
  }, []);

  useLayoutEffect(() => {
    if (hydrated) {
      ref.start();
      ref.current.forEach(onTranslateYChange);
    }
  });
  useLayoutEffect(() => {
    // preload 2nd screen on mainpage
    if (focusedIndex > -1) return;

    const page = pages[activeIndex + 1];
    if (page) {
      const nextPage = document.querySelector(
        '[data-ref="content-' + page.key + '"] '
      );
      if (!nextPage) return;

      const panel = nextPage.querySelector(".panel");
      if (panel) {
        const bgImageUrl = window
          .getComputedStyle(panel)
          .backgroundImage.slice(5, -2);
        if (bgImageUrl) {
          const img = new Image();
          img.src = bgImageUrl;
        }
      }

      for (const img of Array.from(nextPage.querySelectorAll("img")))
        img.removeAttribute("loading");
    }
  }, [activeIndex]);
  useEffect(() => {
    const mask = document.querySelector(
      `[data-ref="content-${pages[last.current.focusedIndex]?.key}"] .mask`
    ) as HTMLElement;
    if (mask) {
      mask.scrollTop = 0;
    }
  }, [focusedIndex]);

  useLayoutEffect(() => {
    if (!hydrated) return;
    requestAnimationFrame(() => {
      for (const path of Array.from(
        document.querySelectorAll('path[data-ref*="path-"]')
      ) as SVGPathElement[]) {
        path.style.setProperty(
          "--path-length",
          String(path.getTotalLength().toFixed(3))
        );
      }
    });
    requestAnimationFrame(() => {
      document.documentElement.classList.add("hydrated");
    });
  }, [width, height, hydrated]);
  useLayoutEffect(() => {
    setHydrated(true);
    setTimeout(() => {
      last.current.notRenderedYet = false;
    }, 100);
  }, []);
  console.log(
    "Render",
    hydrated ? "Hydrated" : "Not hydrated",
    typeof window == "undefined" ? "Server" : "Client",
    pathname,
    activeIndex,
    focusedIndex,
    focusedSection
  );
  const BordersSvg = useMemo(() => {
    return (
      <div>
        <animated.svg
          onClick={() => focusIndex(-1, 0)}
          className={`logo`}
          style={
            {
              "--dark-color": springs["--logo-coloration"].to({
                range: [0, 1],
                output: ["#FFFFFF", "var(--color-brown-dark)"],
              }),
              "--light-color": springs["--logo-coloration"].to({
                range: [0, 1],
                output: ["#FFFFFF", "var(--color-brown-light)"],
              }),
              "--logo-opacity": springs["--logo-coloration"].to({
                range: [0, 1],
                output: [1, 0],
              }),
              "--backdrop-opacity": springs["--logo-coloration"].to({
                range: [0.5, 0.9],
                output: [1, 0],
                extrapolate: "clamp",
              }),
              "--backdrop-scale": springs["--logo-scale-progress"].to({
                range: [0, 0.9],
                output: [1, 0.85],
                extrapolate: "clamp",
              }),
            } as any
          }
        >
          <g className="logoWrapper">
            <g>
              <clipPath id="logo-clip">
                <animated.path
                  d={springs["--logo-morph-progress"].to((v) =>
                    backdropToCircle(Math.min(1, v * 1.5))
                  )}
                />
              </clipPath>
              <g className="backdrop" data-ref={"backdrop"}>
                <rect
                  x={0}
                  y={0}
                  width={200}
                  height={200}
                  fill="url(#paint0_linear_51_13)"
                  clipPath="url(#logo-clip)"
                ></rect>
              </g>
              <defs>
                <linearGradient
                  id="paint0_linear_51_13"
                  x1="64.3103"
                  y1="14.381"
                  x2="132.405"
                  y2="181.517"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#ED5500" />
                  <stop offset="1" stopColor="#FFA800" />
                </linearGradient>
              </defs>
            </g>
            <g data-ref="rays">
              {Object.keys(logoPaths).map((groupName) => {
                return logoPaths[groupName as keyof typeof logoPaths].map(
                  (p, i) => {
                    return (
                      <path
                        key={groupName + i}
                        d={p}
                        className={groupName}
                        data-ref={groupName}
                      />
                    );
                  }
                );
              })}
            </g>
          </g>
        </animated.svg>
        {hydrated && (
          <svg xmlns="http://www.w3.org/2000/svg" className="borders">
            <path
              data-ref="path-inner-left"
              d={paths.layoutPathInner}
              fill="none"
              stroke="var(--color-brown-dark)"
            />
            <path
              data-ref="path-outer-outline-left"
              d={paths.layoutOuterPath}
              fill="none"
              stroke="var(--color-brown-dark)"
            />
            <path
              data-ref="path-outer-left"
              d={paths.layoutOuterPath}
              fill="none"
              stroke="var(--color-brown-light)"
            />
          </svg>
        )}
      </div>
    );
  }, [height, width, hydrated]);
  return (
    <animated.div
      {...bind()}
      className={`layout ${focusedIndex > -1 ? "inPage" : ""} ${
        hydrated ? "" : "prerendered"
      }`}
      style={{
        ...(hydrated
          ? ({
              "--clip-path-layout": `path("${paths.layoutPathInner}")`,
              "--clip-path-slide": `path("${paths.slide}")`,
              "--clip-path-footer": `path("${paths.footer}")`,
              "--logo-scale": Math.min(
                2,
                width / (isDesktop ? 2 : width < 400 ? 4.5 : 4) / 60
              ),
              "--logo-sign-scale": Math.min(
                1,
                width < 600
                  ? 1
                  : (width / 2 -
                      borders -
                      logoRadius -
                      20 -
                      30 -
                      (isDesktop ? 20 : 0)) /
                      330
              ),
            } as any)
          : !focusedSection
          ? {}
          : {
              "--focus-progress": 1,
              "--focus-delayed-progress": 1,
            }),
      }}
    >
      <GraphicsIconDown
        className={`iconDown ${
          activeIndex > 4 || focusedIndex != -1 ? "finished" : null
        } `}
        onClick={() =>
          focusedIndex != -1
            ? document
                .querySelector(".slide.focused .mask")
                ?.scrollTo({ top: 0, behavior: "smooth" })
            : focusIndex(-1, activeIndex > 4 ? 0 : activeIndex + 1)
        }
      />
      <div
        className="inside"
        data-ref="inside"
        onClickCapture={(e) => {
          const el = e.target as HTMLElement;
          if (
            el.closest(".tray") ||
            el.closest(".back-button") ||
            el.closest("label")
          )
            return;

          const refIndex = parseInt(
            el.closest("[data-ref-index]")?.getAttribute("data-ref-index") ??
              "-1"
          );
          var focusing = false;
          if ((e.target as HTMLElement).closest(".meta")) {
            focusing = focusIndex(refIndex) || false;
          } else if (el.closest(".menuItem") && focusedIndex > -1) {
            document.querySelector(".slide.focused .mask")?.scrollTo({
              top: 0,
              behavior: "smooth",
            });
            focusing = true;
          } else {
            if (activeIndex == refIndex) {
              focusing = focusIndex(refIndex) || false;
            } else {
              focusing = focusIndex(-1, refIndex) || false;
            }
          }
          if ((e.target as HTMLElement).closest('[href*="qna/"]')) {
            focusing = false;
          }
          const link = el.closest("a") as unknown as HTMLLinkElement;
          const hash = decodeURIComponent(
            (link?.closest(`a[href*="#"]`) as HTMLAnchorElement)?.hash || ""
          );
          if (hash) {
            (
              document.querySelector(
                `[id="${hash.substring(1)}"] .mask-inside`
              ) || document.querySelector(`[id="${hash.substring(1)}"]`)
            )?.scrollIntoView({
              behavior: "smooth",
            });
            e.preventDefault();
            e.stopPropagation();
          }
          if (focusing) {
            e.preventDefault();
            e.stopPropagation();
            return;
          }
        }}
      >
        <div className="texture"></div>
        <animated.div
          className="overlay"
          style={{
            zIndex: 1,
            /* @ts-ignore */
            //...springs,
          }}
        >
          <div className="dummy"></div>
          {BordersSvg}
          <Link
            href="/"
            className={` button next prev image back-button`}
            onClickCapture={(e) => {
              var onComplete = () => {
                const swiper = document.querySelector(".slide.focused .swiper");
                // @ts-ignore
                swiper?.swiper?.slideTo(0);
              };
              const mask = document.querySelector(".slide.focused .mask");
              if (mask && mask.scrollTop > 0) {
                document.querySelector(".slide.focused .mask")?.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
                document
                  .querySelector(".slide.focused .mask")
                  ?.addEventListener(
                    "scrollend",
                    (event) => {
                      focusIndex(-1, activeIndex);
                      onComplete();
                    },
                    { once: true }
                  );
                e.preventDefault();
                e.stopPropagation();
              } else {
                focusIndex(-1, activeIndex);
                onComplete();
                e.preventDefault();
                e.stopPropagation();
              }
            }}
          >
            <span>
              <animated.svg className={`iconMenu`} viewBox="0 0 24 24">
                <path d={mdiMenu} fill="rgba(255,255,255,0.5)" />
              </animated.svg>
            </span>
          </Link>
          <div className="tray">
            <Link
              target="_blank"
              href="http://instagram.com/ignisimmensus"
              className="button-like"
            >
              <animated.svg viewBox="0 0 24 24">
                <path d={mdiInstagram} fill="rgba(255,255,255,0.5)" />
              </animated.svg>
            </Link>
            <button
              className="transparent"
              id="select-country-button"
              {...{
                popovertarget: "select-country",
                popovertargetaction: "toggle",
              }}
              onClick={(e) => {
                const pos = (
                  document.querySelector(
                    "#select-country-button"
                  )! as HTMLElement
                ).getBoundingClientRect();
                Object.assign(
                  (document.querySelector("#select-country") as HTMLElement)!
                    .style,
                  {
                    right: window.innerWidth - pos.right + "px",
                    top: pos.bottom + "px",
                  }
                );
              }}
            >
              <animated.svg viewBox="0 0 24 24" className={"button-like"}>
                <path d={mdiEarth} fill="rgba(255,255,255,0.5)" />
              </animated.svg>
            </button>
            {/* @ts-ignore */}
            <div id="select-country" popover="auto" key={hydrated}>
              <div>
                <label htmlFor="select-language">Language</label>
                <label className="radio">
                  <input
                    type="radio"
                    name="language"
                    value="EN"
                    defaultChecked={language === "EN"}
                  />
                  English
                </label>
                <label className="radio">
                  <input
                    type="radio"
                    name="language"
                    value="ID"
                    defaultChecked={language === "ID"}
                  />
                  Bahasa Indonesia
                </label>
              </div>
              <div>
                <label htmlFor="select-market">Market</label>
                <label className="radio">
                  <input
                    type="radio"
                    name="market"
                    value="US"
                    defaultChecked={country === "US"}
                  />
                  Worldwide (USD)
                </label>
                <label className="radio">
                  <input
                    type="radio"
                    name="market"
                    value="ID"
                    defaultChecked={country === "ID"}
                  />
                  Indonesia (IDR)
                </label>{" "}
              </div>
              <button
                id="country-button"
                className="button next primary"
                onClickCapture={() => {
                  const locale = getLocaleFromContext({
                    languageCode:
                      (
                        document.querySelector(
                          'input[name="language"]:checked'
                        ) as HTMLInputElement
                      )?.value || "",
                    countryCode:
                      (
                        document.querySelector(
                          'input[name="market"]:checked'
                        ) as HTMLInputElement
                      )?.value || "",
                  });
                  location.href = `/${locale}${pathname}${location.search}`;
                }}
              >
                <span>Apply changes</span>
              </button>
            </div>
            {totalQuantity > 0 && cart?.checkoutUrl && (
              <Link className={`button next primary`} href={cart.checkoutUrl}>
                <span>
                  <strong>{totalQuantity}</strong>
                  <span className="only-desktop">
                    item{totalQuantity == 1 ? "" : "s"}&nbsp;
                  </span>

                  <animated.svg className={`iconMenu`} viewBox="0 0 24 24">
                    <path d={mdiCart} fill="rgba(255,255,255,0.5)" />
                  </animated.svg>
                </span>
              </Link>
            )}
          </div>
          <div className="accordion" data-ref="onyx">
            {transition((values, item, state, index) => {
              if (item.key == "home") {
                // @ts-ignore
                values = ref.current.find((c) => c.item.key == "onyx")?.springs;
              }
              // @ts-ignore
              values ||= {};
              return (
                <MainMenuItem
                  className={`${
                    activeIndex - item.index > 2 ? "behind-2" : ""
                  } ${activeIndex - item.index > 3 ? "behind-3" : ""}`}
                  key={String(index)}
                  item={item}
                  isFocused={item.index == focusedIndex}
                  isActive={item.index == activeIndex}
                  style={{
                    "--slide-index": item.index,
                    "--slide-active-index": `calc(${activeIndex} / 100 * 100)`,
                    "--slide-focused-index": `calc(${focusedIndex} / 200 * 200)`,
                    "--slide-pointer-events":
                      focusedIndex > -1 && focusedIndex != item.index
                        ? "none"
                        : "auto",
                    ...(hydrated
                      ? ({} as any)
                      : !focusedSection ||
                        (focusedSection != item.key && item.key != "home")
                      ? {}
                      : {
                          "--slide-progress-towards-menu": 1,
                          "--slide-progress-past-menu": 1,
                          "--slide-progress": 1,
                          "--active": 1,
                          "--focused": 1,
                          "--delayed-focused": 1,
                          "--current": 1,
                          "--collapsed": 0,
                        }),
                  }}
                  children={item.children}
                />
              );
            })}
          </div>
        </animated.div>

        <div className="scroller" id="smooth-wrapper">
          <div className="scrolling" id="smooth-content">
            {transition((values, item) => (
              <ProductsSlide
                products={products}
                menus={menus}
                item={item}
                style={{
                  // @ts-ignore
                  "--slide-index": item.index,
                  "--slide-active-index": `calc(${activeIndex} / 100 * 100)`,
                  "--slide-focused-index": `calc(${focusedIndex} / 200 * 200)`,

                  ...(hydrated
                    ? {
                        //...values,
                        //"--slide-current-y": values["--slide-current-y"]?.to(
                        //  (v) => v + "px"
                        //),
                        display: values["--slide-current-y"]?.to((v) =>
                          v == 0 ? "none" : ""
                        ),
                      }
                    : focusedIndex == item.index
                    ? {
                        "--collapsed": 0,
                        "--focused": 1,
                        "--active": 1,
                        "--delayed-focused": 1,
                        "--current": 1,
                      }
                    : {
                        display: focusedIndex == -1 ? "" : "none",
                        "--collapsed": 1,
                      }),
                }}
                {...(hydrated
                  ? {
                      clipPath: paths.slide,
                    }
                  : {})}
                className={`${
                  (item.index == focusedIndex && "focused") || ""
                } ${(item.index == activeIndex && "active") || ""}`}
                isFocused={item.index == focusedIndex}
                isActive={item.index == activeIndex}
                data-ref={`content-${item.key}`}
                id={item.key}
              >
                {item.children}
                <div slot="more">
                  {focusedSection == "qna" && item.key == "qna"
                    ? children
                    : focusedSection == item.key &&
                      collection && (
                        <Sections
                          sections={collection.sections}
                          allProducts={products}
                        />
                      )}
                </div>
              </ProductsSlide>
            ))}
          </div>
        </div>
      </div>
    </animated.div>
  );
}

function calc(expression: string, width: number, height: number) {
  const prepared = expression
    .replace(/100d?s?vw/g, String(width))
    .replace(/100d?s?vh/g, String(height))
    .replace(/min\(/g, "Math.min(")
    .replace(/max\(/g, "Math.max(")
    .replace(/px/g, "")
    .replace(/calc/g, "");
  return window.eval(prepared);
}
