import { PageContextProvider } from 'vike-react/usePageContext'
export { PageShell }
import  CartProvider from '../components/cart/provider'

const PageShell = function ({ children, pageContext }) {
  return <>
    <div className="body-background"></div>
    <div className="outer-wrapper">
      <PageContextProvider pageContext={pageContext}>
        <CartProvider>
          {children}
        </CartProvider>
      </PageContextProvider>
    </div>
  </>
}
