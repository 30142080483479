import {
  CollectionSections,
  Product,
  ProductVariant,
} from "../../../lib/shopify.jsx";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, Navigation } from "swiper/modules";
import "./style.scss";
import { useMemo } from "preact/compat";
export function WidgetInstructions({
  allProducts,
  section: { items },
}: {
  allProducts: Product[];
  section: CollectionSections["Instructions"];
}) {
  return (
    <Swiper
      slidesPerView="auto"
      style={{
        scrollMarginBottom: 40,
      }}
      watchSlidesProgress={true}
      maxBackfaceHiddenSlides={0}
      className="swiper-instructions"
      navigation={{
        enabled: true,
        disabledClass: "disabled",
        navigationDisabledClass: "disabled",
      }}
      resistanceRatio={0.9}
      slideToClickedSlide={true}
      modules={[Scrollbar, Navigation]}
      onRealIndexChange={(swiper) => {
        swiper.el?.scrollIntoView({
          block: window.innerWidth > 700 ? "nearest" : "end",
        });
      }}
      centeredSlides={false}
      onSliderMove={(swiper) => {
        console.log(swiper);
      }}
    >
      {useMemo(
        () =>
          items.map(({ headline, text }, index) => (
            <SwiperSlide key={index}>
              <div className="step copy" key={index}>
                <h3>{headline}</h3>
                <div dangerouslySetInnerHTML={{ __html: text }} />
              </div>
            </SwiperSlide>
          )),
        []
      )}
    </Swiper>
  );
}
