// https://vike.dev/onRenderClient
export default onRenderClient;

import { hydrate, render } from "preact";
import { PageShell } from "./PageShell.jsx";
import { PageContextClient } from "vike/types";
import type { Store } from "../pages/+store.server.js";

var store: Store = null;
async function onRenderClient(context: PageContextClient) {
  store ||= context.store;
  context.store ||= store;
  const { Page, Layout } = context.config;
  const page = (
    <PageShell pageContext={context}>
      <Layout {...context}>
        <Page {...context} />
      </Layout>
    </PageShell>
  );
  const container = document.querySelector("body");

  if (context.isHydration) {
    hydrate(page, container);
  } else {
    render(page, container);
  }
  document.title = getPageTitle(context);
}

function getPageTitle(pageContext) {
  const title =
    (pageContext.config.documentProps || {}).title ||
    (pageContext.documentProps || {}).title ||
    "Demo";
  return title;
}
