import { useMemo } from "preact/compat";
import "./style.scss";
import { ShopifyCollection } from "../../lib/shopify.jsx";
import { Link } from "../link/index.jsx";

export default function CollectionIntro({
  collection,
}: {
  collection: ShopifyCollection;
}) {
  return (
    <div className="collection-intro">
      <div className="collection-intro-block">
        {useMemo(
          () => (
            <div
              className="collection-intro-description"
              dangerouslySetInnerHTML={{ __html: collection.introText }}
            />
          ),
          []
        )}
        <Link className="button primary" href={`/${collection.handle}`}>
          {collection.introCta}
        </Link>
      </div>
    </div>
  );
}
