import {
  ProductVariant,
  getVariantHandle,
  getVariantPath,
} from "./shopify.jsx";

export function focusVariant(variant: ProductVariant) {
  const handle = getVariantHandle(variant);
  const target = document.querySelector(`[data-product-variant="${handle}"]`);
  target?.scrollIntoView({
    block: target.closest('[data-ref="content-tools"]') ? "start" : "end",
    behavior: "smooth",
  });
  window.history.replaceState(
    window.history.state,
    "",
    getVariantPath(variant)
  );
  document.dispatchEvent(new Event("render"));
}
