import { HTMLAttributes, PropsWithChildren } from "preact/compat";
import { getLocalizedPath } from "../../lib/locales.js";
import { ComponentChild } from "preact";
import { usePageContext } from "vike-react/usePageContext";
import { useEffect, useLayoutEffect, useState } from "react";

if (typeof history != "undefined") {
  var _wr = function (type) {
    var orig = history[type];
    return function () {
      var rv = orig.apply(this, arguments);
      var e = new Event(type);
      window.dispatchEvent(e);
      return rv;
    };
  };
  history.pushState = _wr("pushState");
  history.replaceState = _wr("replaceState");
}
export function Link({
  children,
  href,
  ...props
}: {
  href: string;
  children: ComponentChild | undefined;
  [key: string]: any;
}) {
  const pageContext = usePageContext();
  const [isActive, setActive] = useState(false);
  const url = getLocalizedPath(href || "");
  useEffect(() => {
    const listener = () => {
      setActive(location.pathname == url);
    };
    window.addEventListener("replaceState", listener);
    window.addEventListener("pushState", listener);
    window.addEventListener("popState", listener);
    listener();
    return () => {
      window.removeEventListener("replaceState", listener);
      window.removeEventListener("pushState", listener);
      window.removeEventListener("popState", listener);
    };
  }, []);
  const className = [props.className, isActive && "is-active"]
    .filter(Boolean)
    .join(" ");
  return (
    <a {...props} href={url} className={className}>
      {children}
    </a>
  );
}
