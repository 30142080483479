import { mdiChevronDown } from "@mdi/js";
import { HTMLAttributes, SVGLineElementAttributes } from "react";

export function GraphicsMouseScroll() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 169.505 241.099"
      className="mouse-scroll"
    >
      <g transform="translate(0 0)">
        <path
          d="M1173.765,402.58a84.851,84.851,0,0,0-84.753,84.753v71.593a84.753,84.753,0,1,0,169.505,0V487.333A84.851,84.851,0,0,0,1173.765,402.58Zm70.349,156.346a70.35,70.35,0,1,1-140.7,0V487.333a70.35,70.35,0,1,1,140.7,0Z"
          transform="translate(-1089.012 -402.58)"
          fill="var(--color-brown-light)"
          className="only-no-touch"
        />
        <path
          d="M1112.366,411.278a7.2,7.2,0,0,0-7.2,7.2v40.271a7.2,7.2,0,1,0,14.4,0V418.48A7.2,7.2,0,0,0,1112.366,411.278Z"
          transform="translate(-1027.614 -369.518)"
          fill="var(--color-brown-light)"
          className="only-no-touch"
        />
        <path
          d="M192.231,104.082V102c0-12.407-10.094-22.5-22.5-22.5c-2.802,0-5.484,0.519-7.961,1.459
        C159.665,70.722,150.583,63,139.731,63c-2.947,0-5.76,0.575-8.341,1.61C128.667,55.162,119.624,48,109.231,48
        c-2.798,0-5.496,0.541-8,1.516V22.5c0-12.407-10.094-22.5-22.5-22.5s-22.5,10.093-22.5,22.5v66.259
        c-3.938-5.029-8.673-9.412-14.169-11.671c-6.133-2.52-12.587-2.219-18.667,0.872c-11.182,5.686-15.792,19.389-10.277,30.548
        l27.95,56.563c0.79,1.552,19.731,38.008,54.023,38.008h40c31.54,0,57.199-25.794,57.199-57.506l-0.031-41.491H192.231z
         M135.092,188.079h-40c-24.702,0-40.091-28.738-40.646-29.796l-27.88-56.42c-1.924-3.893-0.33-8.519,3.629-10.532
        c2.182-1.11,4.081-1.223,6.158-0.372c8.281,3.395,16.41,19.756,19.586,29.265l2.41,7.259l12.883-4.559V22.5
        c0-4.136,3.364-7.5,7.5-7.5s7.5,3.364,7.5,7.5V109h0.136h14.864h0.136V71c0-4.187,3.748-8,7.864-8c4.262,0,8,3.505,8,7.5v15v26h15
        v-26c0-4.136,3.364-7.5,7.5-7.5s7.5,3.364,7.5,7.5V102v16.5h15V102c0-4.136,3.364-7.5,7.5-7.5s7.5,3.364,7.5,7.5v10.727h0.035
        l0.025,32.852C177.291,169.014,158.36,188.079,135.092,188.079z"
          fill="var(--color-brown-light)"
          transform="translate(-25, 30)"
          className="only-touch"
        />
      </g>
    </svg>
  );
}

export function GraphicsIconDown(props?: any) {
  return (
    <svg className={`iconDown`} viewBox="0 0 24 24" {...props}>
      <path
        className="shadow"
        d={mdiChevronDown}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d={mdiChevronDown}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="var(--color-brown-light)"
      />
    </svg>
  );
}
