import { mdiStar } from "@mdi/js";
import { useEffect, useState } from "preact/compat";
import {
  EffectCreative,
  Keyboard,
  Navigation,
  Pagination,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Image from "../../../lib/image.jsx";
import {
  CollectionSections,
  Product,
  ProductVariant,
} from "../../../lib/shopify.jsx";
import Buy from "../../buy/index.jsx";
import Material from "./swiper.js";
import "./style.scss";

export function WidgetCarousel({
  allProducts,
  section: { items },
}: {
  allProducts: Product[];
  section: CollectionSections["Carousel"];
}) {
  const width = typeof window == "undefined" ? 700 : window.innerWidth;
  const [isLargeScreen, setIsLargeScreen] = useState(width > 800);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 800);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <div className="carousel for-Candles">
        <Swiper
          key={`carousel-${isLargeScreen ? "large" : "small"}`}
          slidesPerView={1}
          centeredSlides={false}
          slidesPerGroupSkip={0}
          maxBackfaceHiddenSlides={0}
          grabCursor={true}
          keyboard={{
            enabled: true,
          }}
          loop={true}
          lazyPreloadPrevNext={2}
          lazyPreloaderClass="lazy-preloader"
          breakpoints={{
            1: {
              slidesPerView: 1,
              slidesPerGroup: 1,
              effect: "creative",
            },
            800: {
              slidesPerView: 2,
              loopAdditionalSlides: 3,
              loop: false,
              effect: "material",
            },
          }}
          onRealIndexChange={(swiper) => {
            swiper.el.closest(".section")?.scrollIntoView();
          }}
          effect={isLargeScreen ? "material" : "creative"}
          navigation={true}
          modules={[Keyboard, Navigation, EffectCreative, Material, Pagination]}
          scrollbar={false}
          className="mySwiper"
          pagination={true}
          slideToClickedSlide={true}
          creativeEffect={{
            prev: {
              shadow: true,
              translate: [0, 0, -400],
            },
            next: {
              translate: ["100%", 0, 0],
            },
          }}
          spaceBetween={0}
          speed={600}
        >
          {/*
          <div className="carousel-intro">
            <h2>What our customers are saying</h2>
            <div className="endorsement">
              <div className="stars">
                <svg viewBox="0 0 24 24">
                  <path d={mdiStar} />
                </svg>
                <svg viewBox="0 0 24 24">
                  <path d={mdiStar} />
                </svg>
                <svg viewBox="0 0 24 24">
                  <path d={mdiStar} />
                </svg>
                <svg viewBox="0 0 24 24">
                  <path d={mdiStar} />
                </svg>
                <svg viewBox="0 0 24 24">
                  <path d={mdiStar} />
                </svg>
              </div>
            </div>
          </div> */}
          {items.map(
            (
              { photos, featuredProducts, feedback: [feedback] = [] },
              index
            ) => {
              const image =
                photos?.find((b) => b.url.includes("Portrait")) || photos?.[0];
              if (!image) return;
              const { url, width, height } = image;
              return (
                <SwiperSlide key={index}>
                  <div className="swiper-material-wrapper">
                    <div className="column" key={index}>
                      {feedback && (
                        <div className="copy feedback target demo-material-label swiper-material-animate-opacity">
                          <div className="endorsement">
                            <div className="stars">
                              <svg viewBox="0 0 24 24">
                                <path d={mdiStar} />
                              </svg>
                              <svg viewBox="0 0 24 24">
                                <path d={mdiStar} />
                              </svg>
                              <svg viewBox="0 0 24 24">
                                <path d={mdiStar} />
                              </svg>
                              <svg viewBox="0 0 24 24">
                                <path d={mdiStar} />
                              </svg>
                              <svg viewBox="0 0 24 24">
                                <path d={mdiStar} />
                              </svg>
                            </div>
                          </div>
                          <p className="text">
                            <span className="blurb">{feedback.message}</span>
                            <span className="author">{feedback.author}</span>
                          </p>
                        </div>
                      )}
                      <div
                        className="swiper-material-content"
                        data-swiper-material-scale="1"
                      >
                        {url && (
                          <Image
                            className="background demo-material-image"
                            src={url}
                            width={width}
                            height={height}
                            sizes="carousel"
                          />
                        )}
                        {(featuredProducts || []).map((product) => {
                          return (
                            <div
                              className="featured-product swiper-material-animate-opacity"
                              key={product.id}
                            >
                              <Buy
                                allProducts={allProducts}
                                variant={product as unknown as ProductVariant}
                                showInfo={true}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            }
          )}
        </Swiper>
      </div>
    </>
  );
}
