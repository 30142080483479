import Image from "../../../lib/image.jsx";
import {
  CollectionSections,
  Product,
  ProductVariant,
} from "../../../lib/shopify.jsx";
import { mdiPlayCircle } from "@mdi/js";
import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "preact/compat";
import { EffectCreative, Navigation } from "swiper/modules";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import { SlideContext } from "../../slide/index.jsx";
import "./style.scss";
export const useTimelineAnimation = (
  options: {
    className?: string;
    progress?: number;
    duration?: number;
    speedup?: number;
    target?: number | null;
    alternate?: boolean;
    between?: boolean;
    disabled?: boolean;
    onChange?: (index: number) => void;
    onStateChange?: (state: "playing" | "paused") => void;
  } = {}
) => {
  const item = useContext(SlideContext);

  const {
    className = "step",
    progress = 0,
    duration = 15000,
    speedup = 3,
    target = 1,
    alternate = false,
    between = false,
    disabled = !item.isFocused,
  } = options;

  const ref = useRef({
    state: "paused" as "playing" | "paused",
    position: progress * duration,
    frame: 0,
    time: 0,
    direction: 1,
    element: null as HTMLDivElement | null,
    steps: [] as HTMLDivElement[],
    currentIndex: 0,
    disabled,
    target,
    className,
    duration,
    progress,
    alternate,
  });

  ref.current.disabled = disabled;

  const onFrame: FrameRequestCallback = useCallback((time) => {
    const total = ref.current.steps.length - (between ? 1 : 0);
    ref.current.time ||= time;
    ref.current.position =
      ref.current.position +
      (time - ref.current.time) *
        (ref.current.direction * (ref.current.target == null ? 1 : speedup));
    ref.current.progress = ref.current.position / ref.current.duration;
    const lower = ref.current.target ?? 0;
    const upper = ref.current.target ?? 1;

    if (
      ref.current.direction > 0
        ? ref.current.progress >= upper
        : ref.current.progress <= lower
    ) {
      ref.current.progress = ref.current.direction > 0 ? upper : lower;
      if (ref.current.target != null) {
        ref.current.progress = ref.current.target;
        //ref.current.direction = 1;
        pause();
      } else {
        ref.current.direction *= -1;
      }
    }
    ref.current.position = ref.current.progress * ref.current.duration;
    ref.current.time = time;

    var currentIndex = Math.floor(ref.current.progress * total);
    if (
      ref.current.direction == -1 &&
      currentIndex < total &&
      between &&
      ref.current.state == "playing"
    ) {
      currentIndex++;
    }
    ref.current.steps.forEach((step, index) => {
      if (currentIndex == index && ref.current.currentIndex !== currentIndex) {
        ref.current.currentIndex = currentIndex;
        options.onChange?.(index);
      }
    });
    ref.current.currentIndex = currentIndex;
    ref.current.element!.style.setProperty(
      "--progress",
      String(ref.current.progress)
    );
    if (ref.current.state == "playing") schedule();
  }, []);

  function schedule() {
    cancelAnimationFrame(ref.current.frame);
    ref.current.frame = requestAnimationFrame(onFrame);
  }

  function pause() {
    ref.current.state = "paused";
    ref.current.element?.classList.add("paused");
    options.onStateChange?.(ref.current.state);
    cancelAnimationFrame(ref.current.frame);
  }

  function play() {
    ref.current.time = 0;
    ref.current.state = "playing";
    ref.current.element?.classList.remove("paused");
    options.onStateChange?.(ref.current.state);
    schedule();
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          play();
        } else {
          pause();
        }
      },
      { threshold: 0.1 }
    );

    //console.log("observe", ref.current.disabled);
    setTimeout(
      () => {
        if (ref.current.element && !ref.current.disabled) {
          observer.observe(ref.current.element);
        }
      },
      location.search.includes("stop") ? 100000 : 1000
    );
    ref.current.steps = Array.from(
      ref.current.element?.querySelectorAll("." + className) || []
    ) as HTMLDivElement[];

    return () => {
      if (ref.current.element) {
        observer.unobserve(ref.current.element);
      }
      pause();
      cancelAnimationFrame(ref.current.frame);
    };
  }, [ref.current.disabled]);

  const setTarget = (index: number | null) => {
    const total = ref.current.steps.length - (between ? 1 : 0);
    if (index == null) {
      ref.current.target = null;
      ref.current.direction = 1;
    } else {
      ref.current.target = Math.max(
        0,
        (index + (between ? 0 : 1)) / total - (between ? 0 : 0.001)
      );
      ref.current.direction =
        ref.current.progress >= ref.current.target ? -1 : 1;
    }
    play();
  };

  return { timeline: ref.current, setTarget, play, pause };
};

export function WidgetOverview({
  allProducts,
  section: { items = [] },
}: {
  allProducts: Product[];
  section: CollectionSections["Overview"];
}) {
  const [index, setIndex] = useState(0);
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const { timeline, setTarget } = useTimelineAnimation({
    target: null,
    onChange: (index) => {
      setIndex(index);
      timeline.element
        ?.querySelector(".steps")
        ?.classList.toggle("backward", timeline.direction == -1);
    },
  });
  const isDesktop =
    typeof window == "undefined" ? false : window.innerWidth > 800;
  return (
    <div
      className="overview"
      ref={(e) => {
        timeline.element = e;
      }}
      onClick={(e) => {
        if ((e.target as HTMLElement).closest("a")) {
          e.preventDefault();
        }
        const target = timeline.steps.indexOf(
          (e.target as HTMLElement).closest(".step")!
        );
        if (target > -1) {
          setTarget(target);
        } else if ((e.target as HTMLElement).closest(".play")) {
          setTarget(null);
        }
        timeline.element
          ?.querySelector(".steps")
          ?.classList.toggle("backward", timeline.direction == -1);
      }}
    >
      <div className="canvas">
        <Image src="/assets/Green Mix.jpg" height="564" width="434" />

        <svg viewBox="0 0 24 24" className="play">
          <path d={mdiPlayCircle} />
        </svg>
      </div>
      <div className={`steps`}>
        <Swiper
          maxBackfaceHiddenSlides={0}
          direction="vertical"
          onSwiper={setSwiper}
          slidesPerView={isDesktop ? 4 : 1}
          navigation={true}
          scrollbar={false}
          effect={isDesktop ? "slide" : "creative"}
          modules={[Navigation, EffectCreative]}
          onSlideChange={(swiper) => {
            setTarget(swiper.activeIndex);
          }}
          creativeEffect={{
            prev: {
              shadow: true,
              translate: [0, 0, -400],
              opacity: 0,
            },
            next: {
              translate: [0, "100%", 0],
              opacity: 0,
            },
          }}
        >
          <SwiperSlide
            className={`step ${
              index == 0 ? "current" : index > 0 ? "past" : ""
            }`}
          >
            <h3>
              <a href="#">What's in the box?</a>
            </h3>
            <p>
              A unique jar that nobody else has will be your special discovery.
            </p>
          </SwiperSlide>
          <SwiperSlide
            className={`step ${
              index == 1 ? "current" : index > 1 ? "past" : ""
            }`}
          >
            <h3>
              <a href="#">Each jar has a lid</a>
            </h3>
            <p>It keeps your candle fresh, and can extinguish the flame.</p>
          </SwiperSlide>
          <SwiperSlide
            className={`step ${
              index == 2 ? "current" : index > 2 ? "past" : ""
            }`}
          >
            <h3>
              <a href="#">Comes with a candle</a>
            </h3>
            <p>Amazingly, as the candle diminishes, onyx glows brighter.</p>
          </SwiperSlide>
          <SwiperSlide
            className={`step ${
              index == 3 ? "current" : index > 3 ? "past" : ""
            }`}
          >
            <h3>
              <a href="#">Works with tea lights</a>
            </h3>
            <p>Your space will transform easily with the glow of any candle.</p>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}
