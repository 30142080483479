import Image from "../../lib/image.jsx";
import { CollectionSection, Product } from "../../lib/shopify.jsx";
import { mdiShoppingSearch } from "@mdi/js";
import { useEffect, useMemo, useState } from "preact/compat";
import { WidgetCarousel } from "../widgets/carousel/index.jsx";
import { WidgetInstructions } from "../widgets/instructions/index.jsx";
import { WidgetOverview } from "../widgets/overview/index.jsx";
import { WidgetSlides } from "../widgets/slides/index.jsx";
import "./style.scss";
import "./swiper.scss";
import { Link } from "../link/index.jsx";

export function Sections({
  sections,
  allProducts,
  slot,
}: {
  allProducts: Product[];
  sections: CollectionSection[];
  slot?: string;
}) {
  return (
    <div slot={slot}>
      {sections.map((section, index) => (
        <Section section={section} allProducts={allProducts} key={index} />
      ))}
    </div>
  );
}
export function Section({
  allProducts,
  section,
}: {
  allProducts: Product[];
  section: CollectionSection;
}) {
  const landscape =
    section.backgroundImage?.find((b) => b.url.includes("Landscape")) ||
    section.backgroundImage
      ?.slice()
      .sort((b, a) => a.width / a.height - b.width / b.height)[0];
  var portrait =
    section.backgroundImage?.find((b) => b.url.includes("Portrait")) ||
    section.backgroundImage
      ?.slice()
      .sort((a, b) => a.width / a.height - b.width / b.height)[0];
  const image = portrait;
  const [element, setElement] = useState<HTMLDivElement | null>(null);
  useEffect(() => {
    if (!element) return; // Create an Intersection Observer instance
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          // The target element's top edge is intercepted by the top scroll edge
          const s = entry.target.closest(".slide");
          // if (!section) return;
          const menuItem = document.querySelector(`[data-ref="menu-${s?.id}"]`);
          // Check if the target element's top edge intersects with the viewport's top edge
          if (
            entry.rootBounds &&
            entry.isIntersecting // &&
            //entry.intersectionRect.top === entry.boundingClientRect.top
          ) {
            console.log(
              "Child intercepted by top scroll edge:1",
              entry.target,
              menuItem,
              entry,
              section.tagline
            );
            if (menuItem) {
              const originalTagline = menuItem.getAttribute(
                "data-original-tagline"
              );
              const taglineElement = menuItem.querySelector("p");
              if (!originalTagline && taglineElement)
                menuItem.setAttribute(
                  "data-original-tagline",
                  taglineElement?.innerHTML
                );
              if (menuItem.querySelector("p"))
                menuItem.querySelector("p")!.innerHTML =
                  section.tagline || originalTagline || "";
            }
          }
        });
      },
      {
        // Set options for the Intersection Observer
        root: element.closest(".mask"), // Use viewport as the root
        rootMargin: "0px", // No margin applied to the root
        threshold: 0, // 0 threshold for intersection
      }
    );

    // Get all child elements of the parent element
    const childElements = Array.from(element.querySelectorAll(".block-target"));

    childElements.forEach((child) => {
      observer.observe(child);
    });
    return () => {
      childElements.forEach((child) => {
        observer.unobserve(child);
      });
      observer.disconnect();
    };
  }, [element]);

  useEffect(() => {
    const img = element?.querySelector(".background img") as HTMLImageElement;
    if (!img || !image) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const { width, height } = entry.contentRect;
        const ratio = image?.width / image?.height;
        const expectedWidth = height * ratio;
        const cropped = expectedWidth - width;
        img.style.setProperty("--cropped-width", `${cropped}px`);
      }
    });

    resizeObserver.observe(img);

    return () => {
      resizeObserver.unobserve(img);
    };
  }, [element, image?.width, image?.height]);

  const landscapePlacement =
    landscape?.url?.match(
      /(Center|Bottom|Left|Top|Right)(Center|Bottom|Left|Top|Right)/
    )?.[0] || "CenterBottom";
  const portraitPlacement =
    portrait?.url?.match(
      /(Center|Bottom|Left|Top|Right)(Center|Bottom|Left|Top|Right)/
    )?.[0] || "CenterBottom";
  const Widget =
    section.type == "Overview"
      ? WidgetOverview
      : section.type == "Carousel"
      ? WidgetCarousel
      : section.type == "Slides"
      ? WidgetSlides
      : section.type == "Instructions"
      ? WidgetInstructions
      : null;
  return (
    <div
      id={section.menuTitle}
      data-ref-id={section._id}
      className={` section section-${section.type} ${
        image ? "screen copy" : ""
      } ${section.extraCssClasses} `}
      key={section._id}
      ref={setElement}
      data-image-placement={section.imagePlacement}
      data-transition-top={section.transitionTop}
      data-transition-bottom={section.transitionBottom}
    >
      <div className="block-target"></div>
      {portrait && landscape && (
        <>
          <Image
            breakpoints={[
              {
                media: "(orientation: landscape), (min-width: 800px)",
                src: landscape.url,
                width: landscape.width,
                height: landscape.height,
              },
              {
                media: "(orientation: portrait) and (max-width: 799px)",
                src: portrait.url,
                width: portrait.width,
                height: portrait.height,
              },
            ]}
            sizes="background"
            className="background"
            media="(orientation: landscape)"
            src={portrait.url}
            width={portrait.width}
            height={portrait.height}
          />
          <div
            className={`block block-content landscape-${landscapePlacement} portrait-${portraitPlacement}`}
          >
            <h2>{section.title}</h2>
            {useMemo(
              () =>
                section.description && (
                  <div
                    dangerouslySetInnerHTML={{ __html: section.description }}
                  ></div>
                ),
              []
            )}

            {section.ctaText && (
              <div className="block-cta">
                <Link href={section.ctaLink} className="button  next">
                  <span>
                    {section.ctaText}
                    &nbsp;
                    <svg>
                      <path d={mdiShoppingSearch} />
                    </svg>
                  </span>
                </Link>
              </div>
            )}
          </div>
        </>
      )}
      {/* @ts-ignore */}
      {Widget && <Widget section={section} allProducts={allProducts} />}
    </div>
  );
}
