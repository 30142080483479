type TemplateCache = Record<string, string[]>;

export function useCSSAnimations() {
  var values: Map<HTMLElement, Record<string, string>> = new Map();
  const properties = [
    "opacity",
    "transform",
    "filter",
    "stroke-dashoffset",
    "stroke-width",
    "margin-top",
  ] as const;
  const camelCase = {
    "stroke-dashoffset": "strokeDashoffset",
    "stroke-width": "strokeWidth",
  } as const;
  const booleans = {
    "--focused-boolean": "--focused",
    "--current-boolean": "--current",
    "--active-boolean": "--active",
  } as const;
  const expressions: Map<HTMLElement, [(typeof properties)[number], string][]> =
    new Map();
  const parents: Map<HTMLElement, HTMLElement[]> = new Map();
  const roots: Set<HTMLElement> = new Set();

  const templateCache: TemplateCache = {};
  var initialized = false;

  function parseAndCacheTemplate(value: string): string[] {
    // Check if the value is already cached
    if (templateCache[value]) {
      return templateCache[value];
    }

    // Split the string into parts with variable names and static text
    const parts: string[] = [];
    let lastIndex = 0;
    value.replace(/var\((--[\w-]+)\)/g, (match, variableName, index) => {
      // Add the preceding text if there's any
      if (index == 0) {
        parts.push("");
      }
      if (index > lastIndex) {
        parts.push(value.slice(lastIndex, index));
      }
      // Add the variable name
      parts.push(variableName);
      lastIndex = index + match.length;
      return match;
    });

    // Add the remaining part of the string after the last variable
    if (lastIndex < value.length) {
      parts.push(value.slice(lastIndex));
    }

    // Cache the parsed template
    templateCache[value] = parts;
    return parts;
  }
  function reindex() {
    parents.clear();
    roots.forEach((root) => {
      expressions.forEach((_, child) => {
        if (root.contains(child)) {
          parents.set(child, [...(parents.get(child) || []), root]);
        }
      });
    });
  }

  function start() {
    //expressions.clear();
    initialized = false;
    for (const element of Array.from(
      document.querySelectorAll("*")
    ) as HTMLElement[]) {
      const style = getComputedStyle(element);
      for (let property of properties) {
        const value = style.getPropertyValue("--" + property)?.slice(1, -1);
        if (value) {
          expressions.set(element, [
            ...(expressions.get(element) || []),
            [property, value],
          ]);
        }
      }
    }
  }

  if (typeof MutationObserver !== "undefined") {
    requestAnimationFrame(() => {
      var mutation: number;
      const observer = new MutationObserver((list) => {
        cancelAnimationFrame(mutation);
        if (
          Array.from(list).every((e) =>
            (e.target as HTMLElement).closest?.(".swiper")
          )
        )
          return;
        mutation = requestAnimationFrame(() => {
          //console.log(list);

          console.time("MutationObserver");
          reindex();
          start();
          render();
          console.timeEnd("MutationObserver");
        });
      });
      observer.observe(document.body, {
        childList: true,
        subtree: true,
        attributes: true,
        attributeFilter: ["class"],
      });
    });
  }
  var next: number = 0;
  var changes = 0;
  var lastRendered = 0;
  function flush() {
    if (lastRendered < changes) {
      if (!initialized) {
        initialized = true;
        reindex();
      }
      render();
      lastRendered = changes;
      next = requestAnimationFrame(flush);
    } else {
      next = 0;
    }
  }
  function render() {
    next = 0;
    var assignments: [HTMLElement, string, string][] = [];
    expressions.forEach(function PrepareCSSStyle(pairs, el) {
      pairs.forEach(([property, value]) => {
        const template = parseAndCacheTemplate(value);
        const newValue = template
          .map((part, index) => {
            // Even index (0, 2, 4,...) are static texts, odd index (1, 3, 5,...) are variable names
            if (index % 2 === 1) {
              // Variable name
              for (const parent of parents.get(el) || []) {
                const replacement = values.get(parent)?.[part];

                if (replacement != null) return replacement;

                const sourceProperty = booleans[part as keyof typeof booleans];
                if (sourceProperty) {
                  const replacement = values.get(parent)?.[sourceProperty];
                  //       debugger;
                  if (replacement != null) {
                    return replacement == "0" ? "0" : "1";
                  }
                }
              }
              return `var(${part})`; // Fallback to original if no value found
            }
            return part; // Static text
          })
          .join("");

        assignments.push([el, property, newValue]);
      });
    });
    assignments.forEach(function ModifyCSSStyle([el, property, value]) {
      const prop = camelCase[property as keyof typeof camelCase] || property;
      if (value != el.style[prop as any]) {
        // @ts-ignore
        if ((el.assigned ||= {})[prop] !== value) {
          // @ts-ignore
          el.assigned[prop] = value;
          el.style[prop as any] = value;
        }
      }
    });
  }
  return {
    start,
    flush,
    render,
    set(element: HTMLElement, property: string, value: string) {
      changes++;
      next ||= requestAnimationFrame(flush);
      const newValue = property === "--slide-current-y" ? value + "px" : value;
      const current = values.get(element) || {};
      if (!roots.has(element)) {
        roots.add(element);
      }
      if (current[property] != newValue) {
        values.set(element, {
          ...current,
          [property]: property === "--slide-current-y" ? value + "px" : value,
        });
        return true;
      }
      return false;
    },
  };
}
