import { Menu, MenuItem, Products } from "../../lib/shopify";
import { SlotedChildren, useSlots } from "../../lib/useSlots";
import { mdiGestureTap } from "@mdi/js";
import { animated } from "@react-spring/web";
import React, { createContext, useRef } from "preact/compat";
import "./style.scss";
import "#iso/lib/scrollbar";
import { useObserveAndIntersectLazyImages } from "../../lib/preloader.jsx";

export type Item = {
  index: number;
  key: string;
  children: React.ReactNode;
  variables: Record<string, number>;
} & MenuItem;

export const SlideContext = createContext(
  {} as (Item | undefined) & {
    isActive: boolean;
    isFocused: boolean;
  }
);

export default function ProductsSlide({
  className,
  products,
  menus,
  clipPath,
  hasMask,
  children,
  item,
  isActive = true,
  isFocused = true,
  ...props
}: {
  item?: Item;
  products: Products;
  menus: Menu[];
  children: SlotedChildren<
    [item: Item, products: Products, menus: Menu[]],
    "footer" | "children" | "top" | "more"
  >;
  isActive?: boolean;
  isFocused?: boolean;
  [key: string]: any;
}) {
  return useSlots(
    children,
    ({ top, footer, children, more }) => {
      return (
        <SlideContext.Provider value={{ ...item!, isFocused, isActive }}>
          <Slide
            {...props}
            className={`product-slide slide ${className}`}
            top={top}
            clipPath={clipPath}
            data-ref-index={`${item!.index}`}
            bottom={
              <>
                {footer?.[0].props.children?.props.children?.length > 1 && (
                  <Slide className="footer">{footer}</Slide>
                )}
              </>
            }
          >
            <div className="panel"></div>
            {children}
            {more}
          </Slide>
        </SlideContext.Provider>
      );
    },
    item!,
    products,
    menus
  );
}

export function Slide({
  className,
  clipPath,
  hasMask,
  children,
  bottom,
  top,
  item,
  isActive = true,
  isFocused = true,
  ...props
}: {
  item?: Item;
  children: React.ReactNode;
  bottom?: React.ReactNode;
  top?: React.ReactNode;
  isActive?: boolean;
  isFocused?: boolean;
  [key: string]: any;
}) {
  const ref = useRef<HTMLDivElement | null>(null);
  useObserveAndIntersectLazyImages(ref.current);
  return (
    <animated.div {...props} className={`${className}`}>
      <div className="shadow">
        <div className="shadowTop"></div>
        <div className="shadowCenter"></div>
      </div>
      <svg className="path">
        <path className="path-shadow" d={clipPath} />
        <path className="path-top" d={clipPath} />
      </svg>
      <div className="mask" ref={ref}>
        <div className="scroll-shadows">
          <div className="scroll-shadow-top"></div>
        </div>
        <div className="mask-inside">{children}</div>
      </div>
      {top}
      {bottom}
    </animated.div>
  );
}
