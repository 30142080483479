import {
  CollectionSection,
  CollectionSections,
  Products,
} from "../../lib/shopify.jsx";

export default function SliderFooter({
  sections,
  slot,
}: {
  sections: CollectionSection[];
  slot?: string;
}) {
  return (
    <div className="inside" slot={slot}>
      <ul className="list">
        {sections
          ?.filter((section) => section.menuTitle)
          .map((section, index) => {
            return (
              <li key={index}>
                <a href={"#" + section.menuTitle}>{section.menuTitle}</a>
              </li>
            );
          })}
      </ul>
    </div>
  );
}
