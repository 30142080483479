import { forwardRef } from "preact/compat";
import { createSrcSet } from "img-optimizer";

export function Source({
  src,
  sizes,
  loader,
  unoptimized,
  quality,
  width,
  height,
  ...rest
}: any) {
  return (
    <source
      {...rest}
      srcSet={
        src.startsWith("data:") ? src : createSrcSet(src, undefined, quality)
      }
      sizes={sizes}
    />
  );
}
