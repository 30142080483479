import Buy from "../../buy/index.jsx";
import Image from "../../../lib/image.jsx";
import {
  CollectionSections,
  Product,
  ProductVariant,
} from "../../../lib/shopify.jsx";
import { useMemo, useState } from "preact/compat";
import {
  EffectCreative,
  Keyboard,
  Navigation,
  Pagination,
  Scrollbar,
  Zoom,
} from "swiper/modules";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import "./style.scss";

export function WidgetSlides({
  allProducts,
  section: { items = [] },
}: {
  allProducts: Product[];
  section: CollectionSections["Slides"];
}) {
  const [thumbsSwiper, setThumbsSwiper] = useState<null | SwiperClass>(null);

  return (
    <div className="widget copy slides">
      <Swiper
        maxBackfaceHiddenSlides={0}
        className={`slides-small for-Candles`}
        key={"slides-small"}
        slidesPerView={1}
        scrollbar={false}
        centeredSlides={false}
        slidesPerGroupSkip={1}
        grabCursor={true}
        keyboard={{
          enabled: true,
        }}
        loop={true}
        effect={"slide"}
        slideToClickedSlide={true}
        navigation={true}
        pagination={true}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[
          Keyboard,
          Navigation,
          EffectCreative,
          Zoom,
          Scrollbar,
          Pagination,
        ]}
        spaceBetween={0}
        speed={600}
        onRealIndexChange={(swiper) => {
          swiper.el.closest(".section")?.scrollIntoView();
        }}
      >
        {items.map(({ photos, text, featuredProducts }, index) => {
          const landscape =
            photos?.find((b) => b.url.includes("Landscape")) ||
            photos
              ?.slice()
              .sort((b, a) => a.width / a.height - b.width / b.height)[0];
          var portrait = photos
            ?.slice()
            .sort((a, b) => a.width / a.height - b.width / b.height)[0];
          if (!portrait) return;
          const landscapePlacement =
            landscape?.url?.match(
              /(Center|Bottom|Left|Top|Right)(Center|Bottom|Left|Top|Right)/
            )?.[0] || "CenterTop";
          const portraitPlacement =
            portrait?.url?.match(
              /(Center|Bottom|Left|Top|Right)(Center|Bottom|Left|Top|Right)/
            )?.[0] || "CenterTop";
          return (
            <SwiperSlide key={index}>
              <Image
                breakpoints={[
                  {
                    media: "(orientation: landscape), (min-width: 800px)",
                    src: landscape.url,
                    width: landscape.width,
                    height: landscape.height,
                  },
                  {
                    media: "(orientation: portrait) and (max-width: 799px)",
                    src: portrait.url,
                    width: portrait.width,
                    height: portrait.height,
                  },
                ]}
                sizes="background"
                className="background"
                media="(orientation: landscape)"
                src={portrait.url}
                width={portrait.width}
                height={portrait.height}
              />

              {useMemo(
                () => (
                  <div
                    className={`block block-content  landscape-${landscapePlacement} portrait-${portraitPlacement} has-${
                      featuredProducts?.length || 0
                    }-products`}
                    dangerouslySetInnerHTML={{ __html: text }}
                  ></div>
                ),
                []
              )}
              {(featuredProducts || []).map((variant) => {
                return (
                  <div
                    className="featured-product swiper-material-animate-opacity"
                    key={variant.id}
                  >
                    <Buy
                      allProducts={allProducts}
                      variant={variant as unknown as ProductVariant}
                      showInfo={true}
                    />
                  </div>
                );
              })}
            </SwiperSlide>
          );
        })}
      </Swiper>
      {/* && 
      <Swiper
        freeMode={true}
        watchSlidesProgress={true}
        className={`slides-thumbnails`}
        key={"slides-thumbnails"}
        centeredSlides={false}
        slidesPerGroupSkip={Math.min(items.length, 10)}
        slidesPerGroup={Math.min(items.length, 10)}
        slidesPerView={Math.min(items.length, 10)}
        grabCursor={true}
        loop={false}
        effect={"slide"}
        modules={[Keyboard, Navigation]}
        spaceBetween={0}
        speed={600}
        centerInsufficientSlides={true}
      >
        {items
          .concat(items, items)
          .map(
            ({ photos: [{ url, width, height }], text, placement }, index) => (
              <SwiperSlide className="block">
                {url && (
                  <Image
                    sizes="background"
                    className="background"
                    src={url}
                    width={width}
                    height={height}
                  />
                )}
              </SwiperSlide>
            )
          )}
      </Swiper> */}
    </div>
  );
}
