import { useCart } from "../cart/provider.js";
import { focusVariant } from "../../lib/actions.js";
import { Product, ProductVariant, formatPrice } from "../../lib/shopify.js";
import { mdiCartPlus, mdiMinus, mdiPlus, mdiShoppingSearch } from "@mdi/js";
import "./style.scss";

export default function Buy({
  variant,
  allProducts,
  showInfo = false,
  compareAtPrice,
}: {
  allProducts: Product[];
  variant: Pick<
    ProductVariant,
    "id" | "price" | "title" | "handle" | "shortTitle"
  > & {
    product: Pick<Product, "id">;
  };
  compareAtPrice?: {
    amount: string;
    currencyCode: string;
  };
  showInfo?: boolean;
}) {
  const { addToCart, lines } = useCart();
  const inCart =
    lines.find((item) => item.merchandise.id == variant.id)?.quantity ?? 0;
  const product = allProducts.find(
    (product) => product.id == variant.product.id
  );
  return (
    <div
      className={`buyer ${inCart! > 0 ? "active" : ""} ${
        showInfo ? "show-info" : ""
      }`}
    >
      {showInfo && (
        <button
          className="button"
          onClick={() => {
            focusVariant(variant as any as ProductVariant);
          }}
        >
          <span>
            {
              // @ts-ignore
              variant.referenceTitle?.value ||
                `${product?.title} / ${variant.title}`
            }
            &nbsp; &nbsp;
            <svg>
              <path d={mdiShoppingSearch} />
            </svg>
          </span>
        </button>
      )}

      <div className="controller">
        {inCart > 0 && (
          <>
            <button
              className="badge secondary  button remove"
              onClick={() => {
                addToCart({
                  id: variant.id,
                  quantity: -1,
                });
              }}
            >
              <svg>
                <path d={mdiMinus} />
              </svg>
            </button>

            <p className="quantity">{inCart}</p>
          </>
        )}
        <button
          className="badge primary next button"
          onClick={() => {
            addToCart({
              id: variant.id,
              quantity: +1,
            });
          }}
        >
          <span>
            {formatPrice(variant.price)}&nbsp;
            <svg>
              <path d={inCart > 0 ? mdiPlus : mdiCartPlus} />
            </svg>
          </span>
        </button>
        {compareAtPrice && (
          <>
            <div className="saving">
              Save{" "}
              <strong>
                {formatPrice({
                  amount: (
                    parseFloat(compareAtPrice.amount) -
                    parseFloat(variant.price.amount)
                  ).toFixed(0),
                  currencyCode: compareAtPrice.currencyCode,
                })}
              </strong>
              !
            </div>
            <div className="price previous">{formatPrice(compareAtPrice)}</div>
          </>
        )}
      </div>
    </div>
  );
}
