import * as Sentry from "@sentry/browser";
import type { ProductVariant } from "./shopify.server.js";
export type * from "./shopify.server.js";
import "#iso/lib/fetch";
import { usePageContext } from "vike-react/usePageContext";

export async function getCurrencyRates() {
  return (
    await (
      await fetch(
        "https://v6.exchangerate-api.com/v6/225551f6efdf77909096b27e/latest/IDR"
      )
    ).json()
  )?.conversion_rates as Record<string, number>;
}
export function formatPrice(price: { amount: string; currencyCode: string }) {
  const page = usePageContext();
  if (page.locale == "en" || page.locale.endsWith("US")) {
    price = {
      currencyCode: "USD",
      amount: (parseFloat(price.amount) * page.store.rates["USD"]).toFixed(2),
    };
  }
  if (price.currencyCode == "IDR") {
    return Math.floor(parseFloat(price.amount) / 1000).toFixed(0) + "Rb ";
  } else {
    return "$" + Math.floor(parseFloat(price.amount));
  }
}
export function formatDimensions({ value, unit }: Dimension) {
  return value + unit;
}
export type Dimension = {
  value: number;
  unit: "cm" | "in" | "g";
};
export const shopifyQuery = shopifyQueryRaw;

export async function shopifyQueryRaw<T = any>(
  query: string,
  variables: any | null = null,
  endpoint = process.env.PUBLIC_ENV__SHOPIFY_STORE_GRAPHQL as string,
  token = process.env.PUBLIC_ENV__SHOPIFY_STOREFRONT_ACCESS_TOKEN as string,
  header = "X-Shopify-Storefront-Access-Token"
) {
  try {
    const time = new Date();
    const result = await Sentry.startSpan(
      {
        name:
          query
            .split(/[\(\{]/)[0]
            .trim()
            .split(/\s/)
            .pop() || "query",
        op: "shopify",
      },
      () =>
        fetch(endpoint + "graphql.json", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            [header]: token,
          },
          body: JSON.stringify({ query, variables }),
        })
    );
    //console.log(
    //  Number(new Date()) - Number(time),
    //  "ms",
    //  query.substring(0, 100),
    //  variables
    //);

    if (result.status >= 400) {
      console.log(token, "???");
      try {
        console.log(
          endpoint,

          "Result:",
          {
            "Content-Type": "application/json",
            [header]: token,
          },
          JSON.stringify(await result.json(), undefined, 2)
        );
      } catch (e) {}
      throw new Error(`Shopify HTTP ${result.status}`);
    }

    const json = await result.json();

    if ("errors" in json) {
      console.log(json.errors);
      throw new Error("Shopify Error" + JSON.stringify(json.errors));
    }
    return json as T;
  } catch (error) {
    console.error(`Error: `, error);
    throw error;
  }
}

export type CartInput = Partial<{
  attributes: {
    key: string;
    value: string;
  }[];
  buyerIdentity: Partial<{
    countryCode: string;
    customerAccessToken: string;
    deliveryAddressPreferences: {
      customerAddressId: string;
      deliveryAddress: {
        address1: string;
        address2: string;
        city: string;
        company: string;
        country: string;
        firstName: string;
        lastName: string;
        phone: string;
        province: string;
        zip: string;
      }[];
    };
    email: string;
    phone: string;
    walletPreferences: string[];
  }>;
  discountCodes: string[];
  lines: {
    attributes: {
      key: string;
      value: string;
    }[];
    merchandiseId: string;
    quantity: number;
    sellingPlanId: string;
  }[];
  metafields: {
    key: string;
    type: string;
    value: string;
  }[];
  note: string;
}>;

export async function createCart({
  input = {},
  ...context
}: { input?: CartInput } & ShopifyContext) {
  const {
    data: {
      cartCreate: { cart },
    },
  } = await shopifyQueryRaw<{
    data: {
      cartCreate: {
        cart: CartData;
      };
    };
  }>(
    `mutation createCart($input: CartInput!,$countryCode: CountryCode!, $languageCode: LanguageCode!) @inContext(country: $countryCode, language: $languageCode) {
        cartCreate(input: $input) {
          cart {
            ${cartFields}
          }
        }
      }`,
    {
      input,
      ...context,
    }
  );

  return cart;
}

export type CartLineInput = {
  attributes?: {
    key: string;
    value: string;
  }[];
  merchandiseId: string;
  quantity: number;
  sellingPlanId?: string;
};
export type CartLineUpdateInput = CartLineInput & {
  id: string;
};

const cartFields = `
id
lines(first: 10) {
  edges {
    node {
      id
      quantity
      merchandise {
        ... on ProductVariant {
          id
          title
          image {
            originalSrc
          }
        }
      }
    }
  }
}
cost {
  totalAmount {
    amount
    currencyCode
  }
}
buyerIdentity {
  countryCode
  email
  phone
}
checkoutUrl
totalQuantity`;

export type ShopifyContext = {
  countryCode: string;
  languageCode: string;
};
export async function updateCartLines(
  cartId: string,
  lines: CartLineUpdateInput[],
  context: ShopifyContext
) {
  const {
    data: {
      cartLinesUpdate: { cart },
    },
  } = await shopifyQueryRaw<{
    data: {
      cartLinesUpdate: {
        cart: CartData;
        userErrors: {
          field: string;
          message: string;
        }[];
      };
    };
  }>(
    `
      mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!, $countryCode: CountryCode!, $languageCode: LanguageCode!) @inContext(country: $countryCode, language: $languageCode) {
        cartLinesUpdate(cartId: $cartId, lines: $lines) {
          cart {
            ${cartFields}
          }
          userErrors {
            field
            message
          }
        }
      }
        `,
    {
      ...context,
      cartId,
      lines,
    }
  );

  return cart;
}

export async function addCartLines(
  cartId: string,
  lines: CartLineInput[],
  context: ShopifyContext
) {
  const {
    data: {
      cartLinesAdd: { cart },
    },
  } = await shopifyQueryRaw<{
    data: {
      cartLinesAdd: {
        cart: CartData;
        userErrors: {
          field: string;
          message: string;
        }[];
      };
    };
  }>(
    `
      mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!, $countryCode: CountryCode!, $languageCode: LanguageCode!) @inContext(country: $countryCode, language: $languageCode) {
        cartLinesAdd(cartId: $cartId, lines: $lines) {
          cart {
            ${cartFields}
          }
          userErrors {
            field
            message
          }
        }
      }
        `,
    {
      ...context,
      cartId,
      lines,
    }
  );

  return cart;
}

export type CartLine = {
  id: string;
  quantity: number;
  merchandise: {
    id: string;
    title: string;
    image: {
      originalSrc: string;
    };
  };
};

export type CartData = {
  id: string;
  totalQuantity: number;
  lines: { edges: { node: CartLine }[] };
  cost?: {
    totalAmount: {
      amount: string;
      currencyCode: string;
    };
  };
  buyerIdentity?: Partial<{
    countryCode: string;
    email: string;
    phone: string;
  }>;
  checkoutUrl?: string;
};

export type GetCartResponse = {
  data: {
    cart: CartData;
  };
};

export async function getCart(cartId: string): Promise<CartData> {
  const {
    data: { cart },
  } = await shopifyQueryRaw<GetCartResponse>(
    `
      query getCart($cartId: ID!) {
        cart(id: $cartId) {
          ${cartFields}
        }
      }
    `,
    {
      cartId,
    }
  );

  return cart;
}

export function getVariantHandle(variant: any) {
  return typeof variant.handle == "string"
    ? variant.handle
    : variant.handle?.value || null;
}

export function getVariantPath(variant: ProductVariant) {
  const handle = getVariantHandle(variant);
  return location.pathname.replace(
    /\/([^\/]+)\/?.*/,
    (m, c) => "/" + c + "/" + handle
  );
}
