import { ProductVariant, Products } from "../../lib/shopify.server.js";
import { mdiPlus } from "@mdi/js";
import { Fragment, useState } from "preact/compat";
import Buy from "../buy/index.jsx";
import ProductVariantPhoto from "../product_variant_photo/index.jsx";
import "./style.scss";

export default function ListBundles({
  products,
  allProducts,
  activeId,
  loading,
}: {
  allProducts: Products;
  products: Products;
  activeId?: string;
  loading?: string;
}) {
  return (
    <>
      <div
        className="screen full top-screen"
        style={{
          /*@ts-ignore*/
          "--slide-current-offset": "100px",
          "--slide-collapsed-offset": "0px",
          "--slide-focused-offset": "-100px",
        }}
      >
        <div className="list-bundles">
          {products.map((product, index) => (
            <Bundle
              key={index}
              product={product}
              index={index}
              allProducts={allProducts}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export function Bundle({
  product,
  index,
  allProducts,
}: {
  product: Products[number];
  index: number;
  allProducts: Products;
}) {
  const [components, setComponents] = useState<Record<string, ProductVariant>>(
    {}
  );
  const variants = product.variants.filter((variant) => {
    return variant.selectedOptions.every((option, index) => {
      return !components[index] || components[index].title == option.value;
    });
  });
  const variant = variants[Math.floor(Math.random() * variants.length)];
  const compareAtPrice = product.bundleComponents.reduce(
    (acc, c) => {
      const componentVariant = allProducts
        .flatMap((p) => p.variants)
        .find((v2) => v2.id == c.componentVariants[0]);
      const price = componentVariant.price;
      return {
        amount: (parseFloat(acc.amount) + parseFloat(price.amount)).toFixed(0),
        currencyCode: price.currencyCode,
      };
    },
    {
      amount: "0",
      currencyCode: variant.price!.currencyCode,
    }
  );
  return (
    <div
      className={`product-bundle of-${product.bundleComponents.length}`}
      style={
        {
          "--index": String(index),
        } as any
      }
    >
      <div className="bundle-information">
        <Buy
          allProducts={allProducts}
          variant={variant}
          compareAtPrice={compareAtPrice}
        />
        <h2>{product.title}</h2>
        <div className="description block copy">{product.description}</div>
      </div>
      <div className="bundle-components">
        {product.bundleComponents.map((component, i) => {
          const p = component.componentProduct!;
          const v = p.variants[0];
          return (
            <Fragment key={i}>
              {i > 0 && (
                <svg className="plus" viewBox="0 0 24 24">
                  <path d={mdiPlus} />
                </svg>
              )}
              <ProductVariantPhoto
                className={`cropped scaled`}
                onVariantChange={(variant) => {
                  setComponents((v) => ({
                    ...v,
                    [i]: variant,
                  }));
                }}
                withUi={false}
                withThumbnail={p.productType == "Onyx" ? false : undefined}
                withRandom={true}
                variant={null}
                alt={p.title}
                image={
                  p.productType == "Candles"
                    ? p.variants[0].thumbnails[0]
                    : undefined
                }
                product={p.handle}
                products={allProducts}
                variants={p.variants.filter((v) =>
                  component.componentVariants.some((id) => id == v.id)
                )}
                index={i}
              />
            </Fragment>
          );
        })}
      </div>
    </div>
  );
}
