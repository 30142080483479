import { Link } from "../../components/link/index.js";
import { Menu, Products } from "../../lib/shopify.jsx";
import { SlotedChildren, useSlots } from "../../lib/useSlots.jsx";
import { animated } from "@react-spring/web";
import { Item } from "../slide/index.jsx";
import "./style.scss";
import { HTMLAttributes, useMemo } from "preact/compat";

export default function MainMenuItem({
  item,
  children,
  isActive,
  isFocused,
  ...props
}: {
  item: Item;
  isActive: boolean;
  isFocused: boolean;
  children: SlotedChildren<[], "menu" | "details">;
} & HTMLAttributes<HTMLDivElement>) {
  return useSlots(children, ({ menu, details }) => {
    return (
      // @ts-ignore
      <animated.div
        key="item"
        {...props}
        className={`menuItem ${(isActive && "active") || ""} ${
          (isFocused && "focused") || null
        } ${props.className}`}
        data-ref={`menu-${item.key}`}
        data-ref-index={`${item.index}`}
      >
        <Link
          href={`/${item.key == "home" ? "" : item.key + "/"}`}
          className="item"
        >
          {menu}
        </Link>
        <div className="meta">
          <div className={"metaInside"}>
            <div className="metaInside-before"></div>
            <div className="metaInside-after"></div>
            {useMemo(() => details, [])}
          </div>
        </div>
      </animated.div>
    );
  });
}
