import ProductVariantPhoto from "../product_variant_photo/index.jsx";
import { ProductVariant, Products } from "../../lib/shopify.jsx";
import {
  FreeMode,
  Grid,
  Navigation,
  Pagination,
  Scrollbar,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "./style.scss";

export default function ListNarrow({
  products,
  allProducts,
  activeId,
  loading,
}: {
  allProducts: Products;
  products: Products;
  activeId?: string;
  loading?: string;
}) {
  const variants = products
    .flatMap((product) =>
      product.variants.filter((variant, index) =>
        variant.listAsSeparateProduct || index == 0 ? true : false
      )
    )
    .sort((a, b) => a.orderIndex - b.orderIndex);

  return (
    <>
      <div
        className="screen full top-screen list-narrow-screen"
        style={{
          /*@ts-ignore*/
          "--slide-current-offset": "90px",
          "--slide-collapsed-offset": "calc(45px + var(--slide-overlap))",
          "--slide-focused-offset":
            "calc(-150px + var(--list-vertical-shift, 0px))",
        }}
      >
        <Swiper
          effect="slide"
          className="list-narrow"
          pagination={true}
          navigation={true}
          slidesPerView={"auto"}
          slidesPerGroup={1}
          onRealIndexChange={(swiper) => {
            swiper.el.closest(".mask-inside")?.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }}
          slideToClickedSlide={false}
          centeredSlidesBounds={true}
          initialSlide={0}
          maxBackfaceHiddenSlides={0}
          modules={[Scrollbar, Navigation, Grid, Pagination, FreeMode]}
        >
          {variants.map((variant, index) => {
            const product = products.find((p) => p.id == variant.product.id)!;
            return (
              <SwiperSlide key={index}>
                <ProductVariantPhoto
                  loading={loading}
                  sizes="narrow"
                  index={index}
                  key={variant.id}
                  variant={variant}
                  products={products}
                  alt={variant.title}
                  variants={[
                    ...(product.variants.map((v) => {
                      return products
                        .find((p) => p.id == v.product.id)
                        ?.variants?.find((vv) => vv.id == v.id);
                    }) as unknown as ProductVariant[]),
                  ]}
                  isFocused={true}
                >
                  {(variant, sameProduct) => {
                    return (
                      <div className="product-meta" slot="below">
                        <div className="product-title" slot="below">
                          {!sameProduct
                            ? product.title + " / " + variant.title
                            : product.title}
                        </div>
                        <div className="product-description">
                          {variant.summary}
                        </div>
                      </div>
                    );
                  }}
                </ProductVariantPhoto>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
}
