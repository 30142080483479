import { Source } from "../components/Source.js";

export default function Image({
  breakpoints,
  priority,
  sizes,
  className,
  ...props
}: {
  breakpoints?: {
    media: string;
    width: number;
    height: number;
    src: string;
    sizes?: string;
  }[];
  priority?: boolean;
  size?: string;
  src: string;
  alt?: string;
  [key: string]: any;
}) {
  const directions =
    sizes == "avatar"
      ? "100px"
      : sizes == "background"
      ? "(max-width: 700px) 200vw, (max-width: 1440px) 100vw, 1440px"
      : sizes == "product"
      ? "(max-width: 800px) 50vw, (max-width: 1440px) 25vw, 360px"
      : sizes == "narrow"
      ? "(max-width: 800px) 50vw, (max-width: 1440px) 465px, 360px"
      : sizes == "carousel"
      ? "(max-width: 800px) 100vw, (max-width: 1440px) 50vw, 720px"
      : "(max-width: 400px) 50vw, (max-width: 800px) 50vw, (max-width: 1440px) 50vw, 400px";

  const setups = breakpoints || [
    {
      media: "(-webkit-min-device-pixel-ratio: 1)",
      src: props.src,
      width: props.width,
      height: props.height,
      sizes: sizes,
    },
  ];
  return (
    <picture {...{ className }}>
      {setups.flatMap(({ src, width, height, media }, index) => {
        return [
          <Source
            key={src + media + 0}
            src={src}
            sizes={directions}
            width={width || 400}
            height={height || 400}
            quality={85}
            media={`${media} and (-webkit-min-device-pixel-ratio: 1.5)`}
          />,
          <Source
            key={src + media + 1}
            src={src}
            sizes={directions}
            width={width || 400}
            height={height || 400}
            quality={90}
            media={`${media} and (-webkit-min-device-pixel-ratio: 1)`}
          />,
        ];
      })}
      {/* @ts-ignore */}
      <img
        alt=""
        width={400}
        height="400"
        style={{
          aspectRatio: `${props.width} / ${props.height}`,
        }}
        {...props}
        sizes={directions}
        //priority={item?.key == focusedSection}
        src={props.src}
        loading={priority ? null : "lazy"}
      />
    </picture>
  );
}
